import HeroSection from "../HeroSection";
import MyServices from "../Services"
import AboutMe from "../Aboutme";
import Testimonials from "../Testimonials";
import ComingSoon from "../ComingSoon";
import Footer from "../Footer";
import Gallery from "../Gallery";
export default function Home(){
    return (
        <>
        <HeroSection />
        <AboutMe/>
        <MyServices/>
       
        {/* <Testimonials/> */}
        <Gallery /> 
        {/* <ComingSoon/> */}
        

        
        </>

    )
}