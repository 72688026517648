import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const ContactMe = () => {
    return (
        <div className="container my-4 px-4">
            <div className="row justify-content-center">
                <div className="col-md-14 col-lg-8 p-2 border rounded text-center shadow-sm mx-auto">
                    <h2 className="contact-heading"></h2>
                    <p className="contact-message">
                  <h5>  <br />
            Thank you very much for choosing us for your inspection. An inspector will contact you very shortly to give you an estimate for
the job and schedule the inspection according to availability. You can also reach us at  <a href="tel:+16479796801">647-979-6801</a>.
<br /><br /><b style={{color: 'rgb(51, 101, 188)'}}> Service Pro Home Inspection</b> is committed to give a professional and timely completion of your inspection. If you don’t hear back
withing two hours, please text us on above mentioned phone number. We will call you right back. You have a great day !!!
<br /><br />
                          <b style={{color: 'rgb(51, 101, 188)'}}> Service Pro Home Inspection</b>
                    </h5> </p>
                    <br />
                </div>
            </div>
        </div>
    );
};

export default ContactMe;
