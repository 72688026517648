// src/components/services/Commercial.jsx


const Commercial = () => {

    const imageStyle = {
        width: '50%',         
        height: 'auto',       
        borderRadius: '8px',  
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)', 
        margin: '20px auto',  
        display: 'block'      
      };

      const olStyle = {
        listStyleType: 'disc',  // Change ordered list to bullet points
        marginLeft: '20px',     // Indent the list to look better
        lineHeight: '1.6'       // Optional: Adjust line height for readability
      };

      const headingStyle = {
        color: 'rgb(51, 101, 188)',        // Set the text color to blue
        fontSize: '24px',     // Make the font size larger
        fontWeight: 'bold',   // Make the text bold
      };
  return (
    <>

      <div className="container my-5 px-4">
        <h1>Home Inspection For Buyers in Ontario</h1>
        <img src="/img/buyers.png" alt="Commercial Inspection" className="img-fluid"  style={imageStyle} />
        <p>
        Before you purchase in the <b>Ontario</b>, you deserve the protection and peace of mind of a thorough home inspection.
       <br />
        For most people, buying a property is the single biggest investment in a lifetime. It’s also the place where you and your family will eat, sleep and spend time together. 
        <br />With all the stress and expenses of buying a home, it can be tempting to skip the inspection. Don’t miss out on valuable information! Your new home could have major problems that cost you thousands of dollars and major headaches down the road.
       
        <br /> <br />
      <h2 style={headingStyle}>Why should I get a home inspection before I buy in the Ontario?</h2> 
      You deserve to know about any problems with the property before you buy and to pay a fair price.
       An inspection can fill in the gaps to give you a complete and accurate picture of your new home.
<br />Here are some of the major advantages to getting a house or condo inspection before you buy.
<br /><br />
<h2 style={headingStyle}>You know exactly what you’re buying</h2>
When you’re house-hunting, sometimes it’s love at first sight. The best features of the house or condo jump out at your right away, whether it’s the natural lighting, exposed brick or spacious kitchen.
<br />But it’s also important to be aware of any problems with the property, especially ones that aren’t obvious. A certified home inspector knows where to look—on the roof, in attics, behind outlet covers—to get the full story. We can also spot any upgrades that don’t meet building code standards, which can be costly to bring up to code later.
<br />Most of the time, the problems we find are normal maintenance issues that can be repaired or negotiated around. However, sometimes there are major issue like a bad foundation or structural damage that can be a major expense. In all cases, it’s better to know about issues before you buy.
<br /><br />
<h2 style={headingStyle}>A home inspection lets you negotiate a fair price</h2>
At the end of our inspection, we give you a full report on the condition of the property. You and your realtor can use this report to negotiate having some repairs done before you buy or lowering the final price.
<br />If the seller won’t negotiate or if there are major problems, a house inspection gives you the opportunity to back out of a bad investment.
<br /><br />
<h2 style={headingStyle}>Learn how to maintain and enhance your new Ontario property</h2>
All homes and condos develop problems over time and need ongoing upkeep. An inspection report provides buyers with a valuable roadmap of the current condition of the home and which areas need attention.
<br />The report gives you practical information like the location of the main water shutoff and the expected lifespan of the roof. With this knowledge, you can prevent or plan for the usual upkeep issues that come with home ownership.
<br /><b>At Service Pro Inspections</b>, we go the extra mile by offering expert advice on how to prioritize and resolve issues. We can help you form a cost-effective plan to address key issues that prevent the biggest risks or that will add the most value to your home.
<br /><br />
<h2 style={headingStyle}>Easier to get mortgage approval and insurance</h2>
Banks and lenders prefer—or often require—you to get a home inspection before they’ll approve your mortgage. That’s because it’s impossible to know the true value of a property without a complete assessment of its condition.
<br />Lenders want to protect their own investment. If there’s a problem with the property that lowers its overall value, they expect you to negotiate a more accurate price.
<br />Fortunately, this lines up with your best interests: paying a fair price for the property you’re getting.
<br />Insurance companies also have certain criteria they need you to meet before they will insure your home. We can advise on which factors might be a problem and how you can move forward.
<br /><br />
<h2 style={headingStyle}>Safety and peace of mind</h2>
When it comes to the health and safety of you and your family, there should be no compromise. That’s why it’s important to check for hidden threats like <b>mold</b> and <b>structural</b> damage.
<br />Our certified home inspectors are equipped to test for these issues and others household risks.
<br />After an inspection, you can enjoy peace of mind along with your new home. You’ll know that your home is safe, that there are no nasty surprises and that you’ve made a good investment.
<br /><br />
<h2 style={headingStyle}>Do I need a condo inspection?</h2>
Yes! You might assume that an inspection is less necessary for a condo, but there can be many pitfalls.
<br />In fact, a condo has an extra level of complexity because it shares so many resources with the rest of the building. 
<br />If there’s a problem with one of the shared systems in the building, like the plumbing, it can affect every unit.
<br />You deserve to know about any potential problems before you commit and to pay a fair price for what you’re getting. Our report and expert advice let you make an informed decision that’s right for you.

<br />
<b>Service Pro Home Inspection </b>is not only a licensed, trusted inspection company. We outshine the competition because our background is in home construction itself. We know houses, from basement to roof and everything in between.
<br />Our background also lets us provide expert guidance for Buyers. Our thorough report outlines any problems—and we can advise you on the best way to proceed to correct issues and get the best value for your home.


     </p>
      </div>

    </>
  );
};

export default Commercial;
