// import data from "../../data/index.json";

// export default function MyServices() {
//   return (
//     <section className="services--section" id="myServices">
//       <div className="services--container">
//         <p className="section--title">Services</p>
//         <h2 className="services--section--heading">What we Offer</h2>
//       </div>
//       <div className="services--section--container">
//         {data?.skills?.map((item, index) => (
//           <div key={index} className="services--section--card">
//             <div className="services--section--img">
//               <img
//                 src={item?.src}
//                 alt="services"
//                 style={{
//                   width: '100px', 
//                   height: 'auto', 
//                   maxWidth: '100%'
//                 }}
//               />
//             </div>
//             {/* imgae for services */}
//             <div className="services--section--card--content"
//             >
//          <h3 className="services--section--title">
//                     {item?.title}</h3>
//         <p className="services--section--description">{item?.description}</p>

//             </div>
//           </div>
//         ))}
//       </div>
//     </section>
//   );
// }import React from 'react';
// src/Pages/Home/MyServices.jsx

import React from 'react';
import { Link } from 'react-router-dom';
import data from '../../data/index.json';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function MyServices() {
  return (
    <section className="services--section" id="myServices">
      <div className="container">
        <div className="row text-center mb-5">
          <div className="col">
            <h2 className="services--section--heading">What We Offer</h2>
          </div>
        </div>
        <div className="services--section--container">
          {data?.skills?.map((item, index) => (
            <div key={index} className="services--section--card">
              <div className="services--section--img">
                <img src={item?.src} alt={item?.title} className="img-fluid" />
              </div>
              <div className="services--section--card--content">
                <h3 className="services--section--title">{item?.title}</h3>
                {/* Adding Learn More button */}
                <Link to={`/services/${item.title.toLowerCase()}`} className="btn btn-primary">
                  Learn More
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
