import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Link as ScrollLink, scroller } from 'react-scroll';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavDropdown } from 'react-bootstrap';
import { FaArrowUp } from 'react-icons/fa'; // Add an icon for the back-to-top arrow

function NavBar() {
  const [navActive, setNavActive] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showBackToTop, setShowBackToTop] = useState(false); // State for back-to-top visibility
  const location = useLocation();
  const navigate = useNavigate();

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
    
    // Show back-to-top button when scrolled 100px down
    if (window.scrollY > 100) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleNav = () => {
    setNavActive(!navActive);
  };

  const closeMenu = () => {
    setNavActive(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1200);
      if (window.innerWidth > 1200) {
        closeMenu();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const logoStyles = {
    width: '120px', // Adjust the width as needed
    height: 'auto', // Maintains aspect ratio
    maxWidth: '100%', // Ensures responsiveness
    margin: '15px' // Center align logo with margin on right
  };

  const isActiveRoute = (route) => {
    return location.pathname.startsWith(route);
  };

  const handleContactMeClick = () => {
    closeMenu();
    navigate('/contact-form');
  };

  const handleScrollLinkClick = (section) => {
    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(() => {
        scroller.scrollTo(section, {
          duration: 500,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: -70,
        });
      }, 100);
    } else {
      scroller.scrollTo(section, {
        duration: 500,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -70,
      });
    }
    closeMenu();
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
    if (!dropdownOpen) {
      navigate('/serving');
    }
  };

  const handleServingOptionClick = (route) => {
    navigate(route);
    closeMenu();
  };

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <nav className={`navbar navbar-expand-lg navbar-light bg-light ${navActive ? 'active' : ''} ${scrollPosition > 0 ? 'navbar-scroll' : ''}`}>
        <div className="container">
          <Link to="/" className="navbar-brand" onClick={closeMenu}>
            <img src={process.env.PUBLIC_URL + '/img/logo.jpg'} alt="Logo" style={logoStyles} />
          </Link>

          <button className="navbar-toggler" type="button" onClick={toggleNav}>
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className={`collapse navbar-collapse ${navActive ? 'show' : ''}`}>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to="/" className={`nav-link ${isActiveRoute('/') ? 'nav-link-pointer' : ''}`} onClick={closeMenu}>
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <a
                  href="#myServices"
                  className="nav-link nav-link-pointer"
                  onClick={() => handleScrollLinkClick('myServices')}
                >
                  Services
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="about-us-details"
                  className="nav-link nav-link-pointer"
                  onClick={() => handleScrollLinkClick('AboutUs')}
                >
                  About Us
                </a>
              </li>
              <li className="nav-item dropdown">
                <NavDropdown
                  title="Serving To"
                  id="basic-nav-dropdown"
                  show={dropdownOpen}
                  onClick={handleDropdownToggle}
                  onMouseEnter={() => setDropdownOpen(true)}
                  onMouseLeave={() => setDropdownOpen(false)}
                >
                  <NavDropdown.Item
                    onClick={() => handleServingOptionClick('/Serving/Buyers')}
                    style={{ display: isActiveRoute('/Serving/Buyers') ? 'none' : 'block' }}
                  >
                    Buyers
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => handleServingOptionClick('/Serving/Realtors')}
                    style={{ display: isActiveRoute('/Serving/Realtors') ? 'none' : 'block' }}
                  >
                   Realtors
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => handleServingOptionClick('/Serving/Sellers')}
                    style={{ display: isActiveRoute('/Serving/Sellers') ? 'none' : 'block' }}
                  >
                    Sellers
                  </NavDropdown.Item>
                </NavDropdown>
              </li>
              <li className="nav-item">
                <Link to="/pricelist" className="nav-link" onClick={closeMenu}>
                  Price List
                </Link>
              </li>
              <li className="nav-item">
                <a
                  href="#Gallery"
                  className="nav-link nav-link-pointer"
                  onClick={() => handleScrollLinkClick('Gallery')}
                >
                  Gallery
                </a>
              </li>
              <li className="nav-item">
                <button
                  onClick={handleContactMeClick}
                  className="btn btn-outline-primary ml-3"
                >
                  Contact Us
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Back to Top Arrow */}
      {showBackToTop && (
        <div className="back-to-top" onClick={scrollToTop}>
          <FaArrowUp size={30} color="white" />
        </div>
      )}
    </>
  );
}

export default NavBar;



// import { useState } from "react";
// import { Link } from "react-scroll";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { NavDropdown } from "react-bootstrap"; 

// function NavBar() {
//   const [navActive, setNavActive] = useState(false);

//   const toggleNav = () => {
//     setNavActive(!navActive);
//   };

//   const closeMenu = () => {
//     setNavActive(false);
//   };

//   const logoStyles = {
//     width: "100px", // Adjust the width as needed
//     height: "auto", // Maintains aspect ratio
//     // maxWidth: "100%", // Ensures responsiveness
//   };

//   return (
//     <nav className={`navbar navbar-expand-lg navbar-light bg-light ${navActive ? "active" : ""}`}>
//       <a className="navbar-brand" href="#">
//         <img src="./img/logo.png" alt="Logo" style={logoStyles} />
//       </a>
//       <button className="navbar-toggler" type="button" onClick={toggleNav}>
//         <span className="navbar-toggler-icon"></span>
//       </button>
//       <div className={`collapse navbar-collapse ${navActive ? "show" : ""}`}>
//         <ul className="navbar-nav ml-auto">
//           <li className="nav-item">
//             <Link
//               to="heroSection"
//               className="nav-link"
//               spy={true}
//               smooth={true}
//               offset={-70}
//               duration={500}
//               onClick={closeMenu}
//             >
//               Home
//             </Link>
//           </li>
//           <li className="nav-item">
//             <Link
//               to="myServices"
//               className="nav-link"
//               spy={true}
//               smooth={true}
//               offset={-70}
//               duration={500}
//               onClick={closeMenu}
//             >
//               Services
//             </Link>
//           </li>
//           <li className="nav-item">
//             <Link
//               to="AboutMe"
//               className="nav-link"
//               spy={true}
//               smooth={true}
//               offset={-70}
//               duration={500}
//               onClick={closeMenu}
//             >
//               About Me
//             </Link>
//           </li>
//           <li className="nav-item">
//             <Link
//               to="Testimonials"
//               className="nav-link"
//               spy={true}
//               smooth={true}
//               offset={-70}
//               duration={500}
//               onClick={closeMenu}
//             >
//               Testimonials
//             </Link>
//           </li>
//         </ul>
//         <Link
//           to="Contact"
//           className="btn btn-outline-primary ml-lg-3"
//           spy={true}
//           smooth={true}
//           offset={-70}
//           duration={500}
//           onClick={closeMenu}
//         >
//           Contact Me
//         </Link>
//       </div>
//     </nav>
//   );
// }

// export default NavBar;
