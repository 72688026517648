// import './App.css';
// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';

// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import NavBar from './Pages/Home/Navbar';
// import Home from './Pages/Home/Homescreen';
// function App() {
//   return (
//     <div className="App">
//       <Router>
//         <div>
//           <NavBar />
//           <Routes>
//             <Route path ="/" element={<Home />}> </Route>
//             <Route path ="*" element={<div>404 Not Found</div>}> </Route>
        
         
//           </Routes>     

//           </div>
//       </Router>
//     </div>
//   );
// }

// export default App;



// src/App.jsx
import './App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollToTop from './Pages/ScrollToTop';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './Pages/Home/Navbar';
import Home from './Pages/Home/Homescreen/index';
import Serving from './Pages/Home/Serving/Serving';
import Realtors from './Pages/Home/Serving/Realtors';
import Buyers from './Pages/Home/Serving/Buyers'
import Sellers from './Pages/Home/Serving/Sellers';
import PriceList from './Pages/Home/PriceList'
import ContactForm from './Pages/Home/ContactForm';
import ContactMe from './Pages/Home/ContactMe';
import AboutUsDetails from './Pages/Home/AboutUsDetails';
import Commercial from './Pages/Home/Services/Commercial';
import Pool from './Pages/Home/Services/Pool';
import Residential from './Pages/Home/Services/Mold';
import Footer from './Pages/Home/Footer';
import Mold from './Pages/Home/Services/Mold';

function App() {
    return (
        <div className="App">
            <Router>
            <ScrollToTop/>
                <NavBar />
                <div className="main-content">
                    <Routes>
                        
                        <Route path="/" element={<Home />} />
                        <Route path="/Serving" element={<Serving />} />
                        <Route path="/Serving/Buyers" element={<Buyers />} />
                       
                        <Route path="/Serving/Sellers" element={<Sellers />} />
                        <Route path="/Serving/Realtors" element={<Realtors />} />
                        <Route path="/pricelist" element={<PriceList />} />
                        <Route path="/contact-form" element={<ContactForm />} />
                        <Route path="/contact-me" element={<ContactMe />} />
                        <Route path="/services/commercial inspection" element={<Commercial />} />
                        <Route path="/services/pool inspection" element={<Pool />} />
                        <Route path="/services/mold inspection" element={<Mold />} />
                        <Route path="/about-us-details" element={<AboutUsDetails />} />


                        <Route path="*" element={<div>404 Not Found</div>} />
                    </Routes>
                </div>
             <Footer/>
            </Router>
        </div>
    );
}

export default App;
