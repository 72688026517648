import React from 'react';

function PriceList() {
    const priceData = [
        { description: "Detached house 1000-2000 Sq.ft", price: "$375.00" },
        { description: "Detached house 2000-3000 sq.ft", price: "$400.00" },
        { description: "Detached house 3000-4000 sq.ft", price: "$450.00" },
        { description: "Over 4000 sq.ft. to add Additional every 100 sq.ft", price: "$10.00" },
        { description: "Semi Detached house up to 2000 sq.ft", price: "$425.00" },
        { description: "Town House", price: "$375.00" },
        { description: "High rise condominium", price: "$350.00" },
        { description: "Commercial unit in a plaza up to 2000 sq.ft", price: "$750.00" },
        { description: "Commercial plaza up to 8000 sq.ft", price: "$1500.00" },
        { description: "Plaza over 8000 sq.ft to add additional for every 100 sq.ft", price: "$25.00" }
    ];

    return (
        <div className="price-list-section">
            <h1 className="price-list-heading">Price List</h1>
            <div className="table-responsive price-list-table-container px-md-3 px-4">
                <table className="table table-bordered table-striped">
                    <thead className="thead-dark">
                        <tr>
                            <th>Descriptions</th>
                            <th>
                                Price <span className="text-small d-block">Excluding HST</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {priceData.map((item, index) => (
                            <tr key={index}>
                                <td>{item.description}</td>
                                <td>{item.price}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default PriceList;
