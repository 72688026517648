const Pool = () => {
  const imageStyle = {
    width: '50%',
    height: 'auto',
    borderRadius: '8px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
    margin: '20px auto',
    display: 'block'
  };

  const olStyle = {
    listStyleType: 'disc', 
    marginLeft: '20px', // Indent the list to look better
    lineHeight: '1.6' // Optional: Adjust line height for readability
  };
  const olStyle1 = {
    listStyleType: 'decimal', 
    marginLeft: '20px', // Indent the list to look better
    lineHeight: '1.6' // Optional: Adjust line height for readability
  };
  const headingStyle = {
    color: 'rgb(51, 101, 188)', // Set the text color to blue
    fontSize: '24px', // Make the font size larger
    fontWeight: 'bold', // Make the text bold
   // textAlign: 'center' // Center the heading
  };

 
  return (
    <>
      <div className="container my-5 px-4">
        <h1 style={{ color: 'rgb(51, 101, 188)' ,fontWeight: 'bold'}}>Mold Inspections in Ontario and GTA </h1>
        <p>
        Mold is one of the most common and critical problems    we do inspections for in Ontario and the GTA. It can pose serious health risks for you and your family, as well as damage to your home.
<br />Even when it grows in areas that don’t affect your breathing, like an attic, it’s still a problem. That’s because mold promotes rot and decay, which can damage the structure of your home.
          <br />
          <br />

          <h2 style={headingStyle}>What is mold?</h2>
          Mold is a fungus that’s found all over the world because it can grow on almost any surface if there’s enough humidity. It’s a natural part of our environment-but much like rain or 

          <img src="/img/mold.png" alt="pool Inspection" className="img-fluid" style={imageStyle} />
          insects, it’s not something you want in your home.
<br />Mold reproduces itself by releasing spores into the air. When these spores land, new patches of mold can begin to grow. 
<br />However, the airborne spores can also be breathed in by humans and other animals. In this way, mold growing indoors can affect the air quality of a home, condo or office.



<h2 style={headingStyle}>What causes mold in homes and buildings? </h2>

Most of the issues we see in home inspection are related to water, and mold is no exception. It will grow anywhere that has a consistent relative humidity level of at least 60%.
<br /><br />Here are some of the areas we see it most often in homes:

<ol style={olStyle}>
  <li><b>Non-ventilated spaces like behind walls and under floors.</b> If you see mold spots on a wall, there’s a good chance we’ll find a bigger mold problem behind, where the humidity is higher.</li>
  <li><b>Attics</b>, especially when they’ve been re-insulated in a way that blocks ventilation. In most cases, mold in the attic won’t affect the air quality of the house. However, it still promotes rot and decay and needs to be removed before it damages your roof.</li>
  <li><b>Basements,</b> which get less sunlight and can be prone to leaks or flooding. </li>
  <li><b>Bathrooms,</b> especially ones with bathtubs or showers. If your bathroom doesn’t ventilate properly, it’s a perfect breeding ground for mold.</li>
  <li><b>Kitchens,</b> especially under the sink where leaks can occur.</li>
  <li><b>Around windows and sills,</b> which can collect condensation.</li>

</ol>

<h2 style={headingStyle}>Signs of mold</h2>
Here are some tips on what to look for when checking for mold in your home:
<ol style={olStyle}>
  <li>Mold comes in many forms. It often looks fuzzy, but sometimes it can simply look like a stain on a wall.</li>
  <li>The most common colours of mold are black, white, green and brown.</li>
  <li>Use your nose-mold has an unpleasant musty smell. It can smell like rotting wood or laundry that was left in the washing machine too long.</li>
  <li>Check the seam between walls and ceilings, along baseboards, around windows and behind/under appliances.</li>
  <li>Check any small, enclosed spaces, like under sinks and in cabinets.</li>
 
</ol>

<h2 style={headingStyle}>Do I need a mold inspection for my GTA property? </h2>

Many times, the mold in the home is obvious. But how do you know if the mold you see indicates a larger, hidden problem or if there’s mold in other areas of your home?
<br />A specially certified mold inspector like Holley Home Inspections can give you the full information you need. After a mold inspection with Holley Home Inspections, you’ll know:

        <ol style={olStyle}>
          <li>Where in the home you have mold, including tough-to-spot areas many homeowners miss.</li>
          <li>What type of mold is in your home-is it black mold or a less dangerous type?</li>
          <li>What kind of threat does this type of mold poses to your health and home?</li>
          <li>An assessment of the water and moisture levels in your house, so you know what’s causing the problem.</li>
          <li>Guidance on what your best options are in terms of mold removal.</li>
          <li>Suggestions for how you can prevent further mold problems in the future.</li>
        </ol>
        Since mold can be dangerous for your health, we recommend a full inspection if you’re at all unsure about the presence or extent of mold in your home.  
       <br /><br />
       <h2 style={headingStyle}>Preventing mold</h2>

       When it comes to mold, prevention is the best defence! By following the steps below, you can prevent mold from developing or coming back to your home.
       <h2 style={headingStyle}>How to prevent mold</h2>
      
       <ol style={olStyle1}>
            <li><b>Watch for damp areas in your home.</b> Pay special attention to basements, closets, windowsills, roofs, and around tubs, sinks, and pipes.</li>
            <li><b>If you find damp spots, fix them immediately.</b> Repair any leaks as soon as you notice them and clean up any pooled water—don’t let it sit.</li>
            <li><b>Use fans in your kitchen and bathroom.</b> Always use exhaust fans in the kitchen when cooking and in the bathroom during showers. Let the fan run for a while after you’re finished cooking or showering.</li>
            <li><b>Watch the humidity level in your basement.</b> Many basements need a forced air heating and cooling system or a dehumidifier to keep the humidity level down.</li>
            
            <img src="/img/mold1.png" alt="pool Inspection" className="img-fluid" style={imageStyle} />

            <li><b>Watch the carpeting and rugs in humid areas.</b> Hang bathroom mats to dry completely after showers. Carpeting in humid areas like basements is not a good idea.</li>
            <li><b>Vent outside.</b> Check to make sure your exhaust fans and clothes dryers are indeed venting to the outside.</li>
            <li><b>Seal tubs and sinks.</b> If seals around tubs and sinks aren’t right, water can leak into and behind walls.</li>
          </ol>
          If you have questions about mold or would like to schedule a mold inspection in the GTA or Ontario, give <b>Service Pro Home Inspections</b> a call today! A thorough inspection will let you deal with any existing problems so you can have peace of mind.
 

        </p>
      </div>
    </>
  );
};

export default Pool;
