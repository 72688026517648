// src/Pages/Home/Areas/Areas.jsx
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

// Using the images from the public/img directory
function Serving() {
    const serving = [
        { name: 'Buyers', path: '/serving/buyers', icon: '/img/buyericon.png' },
        { name: 'Realtors', path: '/serving/realtors', icon: '/img/realtoricon.png' },
        { name: 'Sellers', path: '/serving/sellers', icon: '/img/sellericon.png' }
    ];

    return (
        <div className="serving--section">
            <div className="container mt-5">
                <div className="row">
                    {serving.map((item, index) => (
                        <div key={index} className="col-md-4 mb-4">
                            <div className="card text-center">
                                <div className="card-body">
                                    {/* Display the PNG icon here */}
                                    <img src={item.icon} alt={item.name} style={{ width: '80px', height: '80px' }} className="mb-3" />
                                    <h5 className="card-title">{item.name}</h5>
                                    <br /><br />
                                    <Link to={item.path} className="btn btn-primary">Learn More</Link>
                                </div>
                            </div>
                            <br /><br /><br />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Serving;
