import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';

const galleryData = [
  {
    images: [
      "/img/raj.jpg",
      "/img/ac.jpg",
      "/img/roof.JPG",
      "/img/b2.png",
      "/img/fur.jpg",
      "/img/raj3.jpg",
      "/img/realtor.png",
      "/img/elec.webp",
      "/img/raj4.jpg",
      "/img/b1.png",
    ],
  },
];

export default function Gallery() {
  return (
    <section className="gallery--section" id="Gallery">
      {galleryData.map((gallery, index) => (
        <div key={index} className="gallery--container">
          <Carousel interval={3000} controls={false} indicators={false} pause={false}>
            {gallery.images.map((image, idx) => (
              <Carousel.Item key={idx}>
                <img
                  className="d-block gallery-image"
                  src={image}
                  alt={`Slide ${idx}`}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      ))}
    </section>
  );
}
