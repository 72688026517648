// src/components/services/Commercial.jsx


const Commercial = () => {

    const imageStyle = {
        width: '50%',         
        height: 'auto',       
        borderRadius: '8px',  
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)', 
        margin: '20px auto',  
        display: 'block'      
      };

      const olStyle = {
        listStyleType: 'disc',  // Change ordered list to bullet points
        marginLeft: '20px',     // Indent the list to look better
        lineHeight: '1.6'       // Optional: Adjust line height for readability
      };

      const headingStyle = {
        color: 'rgb(51, 101, 188)',        // Set the text color to blue
        fontSize: '24px',     // Make the font size larger
        fontWeight: 'bold',   // Make the text bold
      };
  return (
    <>

      <div className="container my-5 px-4">
        <h1 style={{ color: 'rgb(51, 101, 188)',fontWeight: 'bold' }}>Commercial Building Inspection & Property Inspection</h1>
        <img src="/img/comm.png" alt="Commercial Inspection" className="img-fluid"  style={imageStyle} />
        <p>
        Choosing a location for your business is a major investment. You’ve researched the neighbourhood and made sure there’s enough square footage to meet your needs. Before you sign papers, it’s time to schedule your commercial building inspection to ensure there are no hidden problems.
        A commercial building inspection is just as important as a home inspection. Your business property has plumbing, wiring and a roof, same as a house - so the same issues can pop up. <br /> An inspection can alert you to liabilities and costs, including:
        <ol style={olStyle} className=" mt-4">
        <li>Leaking or damaged roofs</li>
        <li>Cracks in the foundation of the building</li>
        <li>Plumbing problems like damaged pipes or leaks</li>
        <li>Faulty wiring or too little power for the appliances you need</li>
      </ol> <br />
      <h2 style={headingStyle}>What does a commercial building inspection look for?</h2> 
      <br /> A commercial building inspector knows where to look for common and hard-to-spot problems. We provide a thorough evaluation to help you avoid a bad investment or do the necessary maintenance to keep your new property in good shape. <br /> 
      At Service Pro Inspections, our commercial inspections will check the:
      <ol style={olStyle}>
        <li>Roof</li>
        <li>Foundation</li>
        <li>Plumbing</li>
        <li>Electrical</li>
        <li>Heating and cooling system (HVAC)</li>
        <li>Windows</li>
        <li>Water drainage and seepage</li>
      </ol>
      At the end of the inspection, we provide you with a full building condition report. Your report provides all the  information you need to negotiate terms and care for your new property. <br /> 
Questions about your report? We’re happy to explain any issues or provide advice on how you can resolve issues. We can also structure our report to meet ASTM E2018 professional standards.
<br /> <br />
<h2 style={headingStyle}> Certified roof inspections for commercial properties</h2>

Most commercial buildings have a flat roof, which carries a higher risk of leaks than the peaked roof of a house. That’s why it’s particularly important to know the condition of the roof before your purchase or lease. <br />
We provide infrared roof inspections, using thermal cameras to get a complete view. From our roof evaluation, you’ll know whether the roof needs a simple repair or a full replacement. Once you’re informed, you can save money by negotiating any extra costs into your agreement. Otherwise, you risk the unexpected cost of roof repairs or replacement down the line. <br />
Current owners can also benefit from a non-destructive roof inspection. If you notice problems with your roof, you can save money by calling an unbiased third party before a roofer. We’ll let you know the condition of your roof and whether it needs a minor fix or larger work. You may even be able to extend the lifetime of your roof by making a few timely repairs now. <br />
 <br />
<h2 style={headingStyle}>Support for commercial realtors and their clients</h2>

A full report on the condition of the property is a powerful tool for realtors. It gives you information you need to keep your clients informed and negotiate the best deal. <br />
We go out of our way to provide support for realtors by offering:

<ol style={olStyle}>
    <li>A full range of inspections, including  <b>pre-purchase, pre-listing and pre-delivery inspections </b></li>
    <li><b>Quick response times</b> to avoid delays</li>
    <li> <b>Fast turn-around on reports, often same-day</b></li>
    <li><b>Expert advice and guidance</b> on any questions you have about our report and any issues that come up</li>
      </ol>

      At Service Pro Home Inspections , we focus on finding solutions to problems as they arise. <br />
Most issues that show up on our report aren’t deal-breakers—they’re simply a normal part of maintaining a building. We can advise you and your client on the most cost-effective way to care for the new property. <br /> <br />

<h2 style={headingStyle}>Commercial Clients</h2>

Typical Commercial Clients
<ol style={olStyle}>
    <li>Investors</li>
    <li>Corporations & owner-occupants</li>
    <li>Real-estate investment managers & trusts</li>
    <li>Owners with tenants leasing buildings</li>
    <li>Commercial lenders</li>
    <li>Insurance companies</li>
    <li>Property managers</li>
    <li>Tenants — local & multisite</li>
    <li>Attorneys</li>
    <li>Commercial mortgage companies</li>
    <li>Project managers</li>
    <li>Retailers</li>
</ol>
<h2 style={headingStyle}>Commercial Inspections</h2> We provide commercial roofing &
building inspections for:
<ol style={olStyle}>
<li>Investors</li>
    <li>Industrial Buildings</li>
    <li>Office Buildings</li>
    <li>Strip Malls</li>
    <li>Rental Properties</li>
    <li>Apartment buildings</li>
    <li>Property managers</li>
    <li>Multi-Family Dwellings</li>

</ol>
        </p>
      </div>

    </>
  );
};

export default Commercial;
