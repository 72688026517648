import React from 'react';

const AboutUs = () => {
    const areasServed = [
        'GTA',
        'Niagara Region',
        'Waterloo Region',
        'Guelph',
        'Barrie',
        'Durham'
    ];

    return (
        <>
            {/* About Us Section */}
            <section id="AboutMe" className="about--section">
                <div className="aboutme--section--img">
                    <img src="./img/rajj.jpg" alt="About Me" />
                </div>
                <div className="hero--section--content--box about--section--box">
                    <div className="about--section--content">
                        <h1 className="about-section--heading">About Us</h1>
                        <p className="about--section--description">
                            We specialize in commercial and residential home inspection services including mold inspection, pool & spa inspection, and in-depth septic system inspection. Our home inspectors are highly qualified, trained, and insured.
                        </p>
                        {/* Learn more link */}
                        <a href="/about-us-details" className="btn btn-primary">Learn More</a>
                    </div>
                </div>
            </section>

            {/* Areas We Serve Section */}
            <section id="areasServed" className="areas--section">
                <div className="container mt-5">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <h2>Areas We Serve</h2>
                            <ul className="areas--list">
                                {areasServed.map((area, index) => (
                                    <li key={index}>{area}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            {/* Reviews Section */}
            <section id="reviews" className="reviews--section ">
    <div className="container mt-5">
        <div className="row text-center justify-content-center">
            <div className="col-md-5">
                <h2>Client Reviews</h2>
                <p>Read what our clients say about our services.</p>
                <a href="https://homestars.com/members/2347412-service-pro-home-inspections/reviews" className="btn btn-outline-primary">Read Reviews</a>
            </div>
        </div>
    </div>
</section>

        </>
    );
};

export default AboutUs;
