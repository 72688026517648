// src/components/services/Commercial.jsx


const Commercial = () => {

    const imageStyle = {
        width: '50%',         
        height: 'auto',       
        borderRadius: '8px',  
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)', 
        margin: '20px auto',  
        display: 'block'      
      };

      const olStyle = {
    
        listStyleType: 'disc',  // Change ordered list to bullet points
        marginLeft: '20px',     // Indent the list to look better
        lineHeight: '1.6'       // Optional: Adjust line height for readability
      };

      const headingStyle = {
        color: 'rgb(51, 101, 188)',        // Set the text color to blue
        fontSize: '24px',     // Make the font size larger
        fontWeight: 'bold',   // Make the text bold
      };
  return (
    <>

      <div className="container my-5 px-4">
        <h1 style={{ color: 'rgb(51, 101, 188)' }}>Services for Realtors in Ontario</h1>
        <img src="/img/realtor.png" alt="Commercial Inspection" className="img-fluid"  style={imageStyle} />
        <p>
        Home inspection services can provide valuable information for realtors to help you serve your client and make the sale. That’s true whether your client is the <b>buyer</b> or the <b>seller</b>.
<br />Inspections don’t <i>create</i> problems in a house-those problems are there, even if you or your client doesn’t know about them yet. Being aware of issues gives you the power to adapt, problem-solve and avoid last-minute surprises.

        <br /> <br />
      <h2 style={headingStyle}>At Service Pro Home Inspections, we don’t just point out problems-we also focus on solutions.</h2> 
     
      Having experience in construction Industry for more than 30 years, our owner <b>Raj Burmy</b>  knows that <b>houses require maintenance</b>. It’s normal for things to break down over time and need to be repaired.
<br />When we transfer ownership of a house from one person to another, we also transfer maintenance of the house and property. A home inspection lets buyers know the current state of the house and be aware of the kind of maintenance it will need. It helps both buyers and sellers avoid surprises and keeps things progressing smoothly.
<br />As home construction experts, we can provide guidance for buyers and sellers to maintain their home and its value. Let us help you protect and inform your clients and keep them coming back to you.
<br />But what if a house inspection shows problems?
<br />Yes, an inspection is likely to turn up some problems with the house. But those problems aren’t the end of the sale-they’re a starting point to improve the sale. 
<br />A <b>pre-listing home inspection</b> gives sellers and their realtors the power to solve some problems and be prepared to negotiate on others. After all, most buyers will insist on a house inspection before they buy anyway.
<br />For <b>buyers</b>, it’s comforting to have an inspector who can point out possible solutions as well as problems.
<br />Since our team has a background in home construction as well as inspection, we’re a valuable resource to help you prioritize and plan repairs. By knowing where to focus their energy, your client can increase the value of their home without wasting time or money.
<br />Commercial building inspection services for realtors
<br />In addition to home inspections, we also offer <b>certified commercial building inspections</b>.
<br />Help your clients make an informed decision and negotiate the best price with a full building condition report. <b>We specialize in flat top roof inspections</b>, a major concern for most commercial properties.
<br />We’re on your team
<br />At <b>Service Pro Home Inspections</b>, we know a thorough inspection has strong benefits for both <b>buyers</b> and <b>sellers</b>. That’s why we see ourselves as a resource and source of support for realtors.
<br />We go above and beyond to provide excellent service for realtors and their clients, including:

<ol style={olStyle}>
    <li>Providing the type of inspection you need. <b>We offer pre-purchase, pre-listing and pre-delivery inspections</b></li>
    <li>We provide inspections on <b>homes</b>, <b>condos</b> and <b>commercial buildings</b>.</li>
    <li><b>Quick response times.</b> We respond quickly at any hour of the day or night to help you move forward without delay.</li>
    <li><b>Quick turn-around on reports, often same-day.</b> The faster we get you the report, the faster you can close the deal.</li>
    <li><b>Support that continues after the report.</b> When issues come up with the property being bought or sold, we can help you and your clients decide how to proceed. As experts on the <b><i> Ontario Building Codes</i></b>, local subcontractors and home construction, we’re a valuable resource.</li>
</ol>

Want more information on how we can help you and your clients? Read more about the benefits we provide for <b>buyers</b> and <b>sellers</b> and please give us a call if you have questions.






     </p>
      </div>

    </>
  );
};

export default Commercial;
