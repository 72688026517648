//TESTING
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        address: '',
        message: '',
        houseType: '',
        area: '',
        inspectionDate: ''
    });

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/email', formData);
            if (response.status === 200) {
                setSuccess('Your message has been sent successfully.');
                setError(null);
                setFormData({ name: '', email: '', phone: '', address: '', message: '', houseType: '', area: '', inspectionDate: '' });
                navigate('/contact-me');
            }
        } catch (error) {
            setError('Failed to send your message. Please try again later.');
            setSuccess(null);
        }
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     try {
    //         const response = await axios.post('/api/email', formData);
    //         if (response.status === 200) {
    //             setSuccess('Your message has been sent successfully.');
    //             setError(null);
    //             setFormData({
    //                 name: '', email: '', phone: '', address: '', message: '',
    //                 houseType: '', area: '', inspectionDate: ''
    //             });
    //             navigate('/contact-me');
    //         }
    //     } catch (error) {
    //         // Display the detailed error message from the backend
    //         setError(error.response?.data?.error || 'Failed to send your message. Please try again later.');
    //         setSuccess(null);
    //     }
    // };
    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     try {
    //         const response = await axios.post('http://localhost:3000/api/email', formData);  // Use full localhost URL
    //         if (response.status === 200) {
    //             setSuccess('Your message has been sent successfully.');
    //             setError(null);
    //             setFormData({
    //                 name: '', email: '', phone: '', address: '', message: '',
    //                 houseType: '', area: '', inspectionDate: ''
    //             });
    //         }
    //     } catch (error) {
    //         setError(error.response?.data?.error || 'Failed to send your message. Please try again later.');
    //         setSuccess(null);
    //     }
    // };
    
    
      
    

    return (
        <div className="container mt-5">
            <h2 style={{ textAlign: 'center' }}>Contact Form</h2>
            <div style={{ maxWidth: '600px', margin: '0 auto' }}> {/* Set form width and center */}
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="phone" className="form-label">Phone</label>
                        <input
                            type="text"
                            className="form-control"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="address" className="form-label">Address</label>
                        <input
                            type="text"
                            className="form-control"
                            id="address"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    {/* House Type Selection */}
                    <div className="mb-3">
                        <label htmlFor="houseType" className="form-label">House Type</label>
                        <select
                            className="form-control"
                            id="houseType"
                            name="houseType"
                            value={formData.houseType}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select House Type</option>
                            <option value="Detached">Detached House</option>
                            <option value="Semi-Detached">Semi-Detached House</option>
                            <option value="Townhouse">Townhouse</option>
                            <option value="Condo">Condo</option>
                        </select>
                    </div>

                    {/* Area Input */}
                    <div className="mb-3">
                        <label htmlFor="area" className="form-label">Area</label>
                        <input
                            type="text"
                            className="form-control"
                            id="area"
                            name="area"
                            value={formData.area}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    {/* Date of Inspection */}
                    <div className="mb-3">
                        <label htmlFor="inspectionDate" className="form-label">Date of Inspection</label>
                        <input
                            type="date"
                            className="form-control"
                            id="inspectionDate"
                            name="inspectionDate"
                            value={formData.inspectionDate}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="message" className="form-label">Message</label>
                        <textarea
                            className="form-control"
                            id="message"
                            name="message"
                            rows="5"
                            value={formData.message}
                            onChange={handleChange}
                            required
                        ></textarea>
                    </div>

                    {error && <div className="alert alert-danger">{error}</div>}
                    {success && <div className="alert alert-success">{success}</div>}
                    
                    {/* Button Styling */}
                    <div className="d-flex justify-content-center">
                        <button type="submit" className="btn btn-primary btn-lg">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ContactForm;



// import React from 'react';

// const ContactForm = () => {
//     return (
//         <div className="container mt-5">
//             <h2 style={{ textAlign: 'center' }}>Contact Us</h2>
//             <div style={{ maxWidth: '600px', margin: '0 auto', textAlign: 'center' }}>
//                 <p style={{ fontSize: '18px' }}>
//                     For inquiries, please contact us at:
//                 </p>
//                 <p style={{ fontSize: '22px', fontWeight: 'bold' }}>
//                     <a href="tel:+16479796801">647-979-6801</a>
//                 </p>
//                 <p style={{ fontSize: '16px' }}>
//                     You can also email us at:
//                     <br />
//                     <a href="mailto:serviceproinspections@outlook.com">serviceproinspections@outlook.com</a>
//                 </p>
//                 <br /><br /><br /> <br /><br />
//                 {/* 
//                 <form onSubmit={handleSubmit}>
//                     Your form code here...
//                 </form>
//                 */}
//             </div>
//         </div>
//     );
// };

// export default ContactForm;
