export default function AboutUsDetails() {
  return (
    <section id="AboutUsDetails" className="about--section">
      <div className="about--section--img">
        <img src="./img/raj.jpg" alt="About Us Details" />
      </div>

      <div className="hero--section--content--box about--section--box">
        <div className="hero--section--content">
          <h1 className="about-section--heading">About US</h1>
          <p className="about--section--description">
            We specialize in commercial and residential home inspection services including mold inspection, pool & spa inspection, and in-depth septic system inspection.
            Our home inspectors are highly qualified, trained, and insured. We provide services all over Ontario within 150 kms of the 
            <span className="highlight-blue"> Greater Toronto Area </span>. 
            Not only are we certified and insured home inspectors, but we also have decades of construction industry experience in commercial and residential projects.
          </p>
          <p className="about--section--description">
            We develop long-term relations with our clients, prepare fast and accurate reports even during short notices. We suggest and discuss the most cost-effective ways our clients can make repairs to improve and increase the value of their property. We stand above other inspectors because we not only see the problems but also give solutions and opportunities.
          </p>
          <p className="about--section--description">
            <span className="highlight-blue"> Service Pro Home Inspections </span> has the experience, expertise, and training you can trust to evaluate your property. We're committed to ensuring you have all the necessary details for your decision-making process.
          </p>
        </div>
      </div>

      {/* inspector details */}
      <div className="about--inspector--section">
       
        <div className="hero--section--content">
          <h1 className="about-section--heading">Meet our Certified Home Inspector</h1>
          <p className="about--section--description">
            Raj Burmy is a graduate civil engineer and a professional building inspector.
            Having more than 25 years of construction industry experience, he has worked on various levels from a site engineer to construction project manager, completing houses, plazas, and high-rise buildings.
            Raj has over ten years of inspection experience. Working as a building inspector and a certified home inspector, he completed his home inspection graduation from Carson Dunlop school of home inspection.
          </p>
        </div>
      </div>
      <div className="about--inspector--section--img">
          <img src="./img/ins.png" alt="About Us Details" />
          <p className="inspector-caption">Raj Burmy, CHI, CBCO, C.Tech., B.Eng.</p>
        </div>
    </section>
  );
}
