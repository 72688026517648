const Pool = () => {
  const imageStyle = {
    width: '50%',
    height: 'auto',
    borderRadius: '8px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
    margin: '20px auto',
    display: 'block'
  };

  const olStyle = {
    listStyleType: 'disc', // Change ordered list to bullet points
    marginLeft: '20px', // Indent the list to look better
    lineHeight: '1.6' // Optional: Adjust line height for readability
  };

  const headingStyle = {
    color: 'rgb(51, 101, 188)', // Set the text color to blue
    fontSize: '24px', // Make the font size larger
    fontWeight: 'bold', // Make the text bold
    textAlign: 'center' // Center the heading
  };

  // New style for the background box around Visual Inspection section
  const visualInspectionStyle = {
    backgroundColor: '#f0f8ff', // Light blue background color
    padding: '20px', // Padding around the content
    borderRadius: '10px', // Rounded corners
    margin: '20px 0', // Space above and below the section
    textAlign: 'center' // Center the text inside the box
  };

  const visualHeadingStyle = {
    color: 'rgb(51, 101, 188)', // Blue color for the heading
    fontSize: '24px', // Larger font size for the heading
    fontWeight: 'bold', // Make the heading bold
    marginBottom: '10px' // Space below the heading
  };

  return (
    <>
      <div className="container my-5 px-4">
        <h1 style={{ color: 'rgb(51, 101, 188)',fontWeight: 'bold' }}>What is a Pool Inspection?</h1>
        <p>
          A pool inspection includes a visual examination of the pool's readily accessible systems and components. This includes the condition of the deck, installed equipment, coping & pool liner, skimmer, pumps, jets, filtration system, electrical systems, and plumbing systems.
          <br />
          <br />
          We will report on the components' age, including the liner, condition, visible damage or installation issue and any maintenance-related items as they pertain to the pool.
          <img src="/img/pool.png" alt="pool Inspection" className="img-fluid" style={imageStyle} />
          <br />
          <h2 style={headingStyle}>To determine the Condition of Your Pool</h2>
          Our swimming pool inspections provide the buyer with a snapshot of the present condition of your pool and its liner. This includes <b>recommended repairs, ages of components</b> and the presence of any <b>safety concerns</b>.
          <br />
          Because this is a visual inspection only, we cannot verify the conditions of concealed components, such as underground pipes, or if the pool has any underground leaks. If these are of concern, we recommend having a pool company perform a specialized leak detection pressure test.
          <img src="/img/pool1.png" alt="pool Inspection" className="img-fluid" style={imageStyle} />
          <br />
          <br />
          Ensure your swimming pool is in top condition with our thorough pool inspection services. Whether you buy a home with a pool or seeking an unbiased review of your current pool, our inspections deliver a detailed assessment of your pool's condition. We identify necessary repairs and highlight any safety concerns, providing a comprehensive report to give you peace of mind.
          <img src="/img/pool2.png" alt="pool Inspection" className="img-fluid" style={imageStyle} />
          <br />
          <br />
          <h2 style={headingStyle}>Note on Underground Leaks</h2>
          Please note that our inspections are visual assessments. We cannot detect underground leaks. If you have concerns about potential underground leaks, we recommend contacting a specialized leak detection company to perform a pressure test.
          <br />
          <br />
          {/* Visual Inspection Section */}
          <div style={visualInspectionStyle}>
            <h2 style={visualHeadingStyle}>Visual Inspection</h2>
            <p>
              Pool inspections are a full visual assessment of the accessible components of the pool. Our certified pool inspector will perform a full visual assessment of your swimming pool.
            </p>
          </div>
        </p>
      </div>
    </>
  );
};

export default Pool;
