// src/components/services/Commercial.jsx


const Commercial = () => {

    const imageStyle = {
        width: '50%',         
        height: 'auto',       
        borderRadius: '8px',  
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)', 
        margin: '20px auto',  
        display: 'block'      
      };

      const olStyle = {
        listStyleType: 'disc',  // Change ordered list to bullet points
        marginLeft: '20px',     // Indent the list to look better
        lineHeight: '1.6'       // Optional: Adjust line height for readability
      };

      const headingStyle = {
        color: 'rgb(51, 101, 188)',        // Set the text color to blue
        fontSize: '24px',     // Make the font size larger
        fontWeight: 'bold',   // Make the text bold
      };
  return (
    <>

      <div className="container my-5 px-4">
        <h1 >Inspection for Sellers</h1>
        <img src="/img/seller.png" alt="Commercial Inspection" className="img-fluid"  style={imageStyle} />
        <p>
      
      <h2 style={headingStyle}>Why sellers should  need to do pre-listing inspection</h2> 
      Congratulations, you’ve found a buyer and agreed on a price for your house-pending a home inspection, of course. 
<br />But what happens if the inspection turns up something unexpected that changes the price? Or worse, what if it’s a deal-breaker?
<br />You can avoid surprises and make your house more attractive to buyers by scheduling your own home inspection before you list. 
<br /><br />
<h2 style={headingStyle}>Reasons to get a pre-listing home inspection</h2>
Many sellers go through the process of “staging” their homes when they go on the market. Usually, this means making your home seem more attractive with a new coat of paint and some serious decluttering.
<br />Looks are important, but most buyers are savvy enough to insist on a home inspection before they buy. As a seller, do you want to know about any problems ahead of time, or wait and find out in the middle of a sale?
<br />Here are the top reasons to consider getting an inspection before you list:

<br /><br />
<h2 style={headingStyle}>Providing an inspection builds trust with potential buyers</h2>
Most buyers are careful shoppers, and rightfully so—a house is the single biggest purchase most people make in a lifetime. 
<br />Providing buyers a home inspection report before they purchase tells them that you have nothing to hide. Plus, chances are your buyer will schedule their own inspection before closing anyway.

<br /><br />
<h2 style={headingStyle}>An inspection can help you increase the value of your home</h2>
Making minor repairs before selling can increase the value of your home, often much more than the cost of the repair itself. 
<br />But which repairs are the most crucial? Which offer the best value for your time and money? 
<br />Since the Service Pro Home Inspection team has its background in construction and housebuilding, we can provide guidance on where to focus your energy. 

<br /><br />
<h2 style={headingStyle}>Inspection reports can highlight the selling features of your home</h2>
If you’ve been maintaining and upgrading your home over the years, an inspection can shine a spotlight on these valuable assets. Many buyers won’t notice upgraded wiring or a new roof—unless it’s spelled out for them in a report from a trustworthy inspector.
<br /><br />
<h2 style={headingStyle}>Less chance of price-lowering negotiations</h2>
Homebuyers can usually count on one or two surprises to show up during an inspection. Many buyers use these findings as bargaining chips to lower the agreed-upon price of the home. 
<br />If you’ve already had an inspection done ahead of time, however, you’re likely to know about any issues ahead of time. This saves you time - not to mention stress and frustration - by reducing back-and-forth negotiating.

<br /><br />
<h2 style={headingStyle}>No nasty surprises for you, your realtor or your buyer</h2>
During escrow, your buyer’s home inspection may turn up issues you didn’t even know about. Depending on the issue, it could lower the selling price of your house-or cause the buyer to walk away.
<br />Selling your home is stressful-especially if you’ve already bought a new home and have a tight timeline. Doing your own home inspection ahead of time can lessen the uncertainty and make things run more smoothly.

<br /><br />
<h2 style={headingStyle}>Your realtor will thank you</h2>
Your <b>realtor</b> is on your team, helping you do everything you can to sell your home for the best price.
<br />When you get an inspection before selling, you’re providing your realtor with valuable information that helps them do their job. The information from an inspection can help your realtor price your home accurately, attract buyers and negotiate with confidence. 
<br /><br />
<h2 style={headingStyle}>Choosing a reliable home inspector is crucial</h2>
A home inspection can save sellers time, money and headaches-but only if the inspector is credible. There’s no point in paying for an inspection that misses many of the problems.
<br /> <b>Service Pro Home Inspection</b> is not only a licensed, trusted inspection company. We outshine the competition because our background is in home construction itself. We know houses, from basement to roof and everything in between.
<br />Our background also lets us provide expert guidance for sellers. Our thorough report outlines any problems—and we can advise you on the best way to proceed to correct issues and get the best value for your home.

     </p>
      </div>

    </>
  );
};

export default Commercial;
