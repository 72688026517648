// export default function HeroSection(){
//     return(
//     <section id="heroSection" className="hero--section">

// <div className="hero--section--content--box">
// <div className="hero--section--content">
//     <p className="section--title">Welcome To, </p>
//     <h1 className="hero--section--title">
//         <span className="hero--section--title-color"></span>{" "}
   
// Home Inspection
//     </h1>
// <p className="hero--section--description">
//     Lorem ipsum dolor sit amet consectetur adipisicing elit. 
//     <br />
//     Lorem ipsum dolor sit amet, consectetur adipisicing elit. Obcaecati iste et voluptas.
// </p>
// </div>
// <button className="btn btn-primary"> Get in Touch</button>
// </div>
// <div className="hero--section--img">
//     <img src="./img/inspec.jpg" alt="logo image" />
// </div>
//     </section>


//     )
// }import React from 'react';import React from 'react';

// import { useNavigate } from 'react-router-dom';
// import { Carousel } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

// const HeroSection = () => {
//   const navigate = useNavigate();

//   return (
//     <section id="heroSection" className="hero--section">
//       <Carousel interval={3000} controls={false} indicators={false}>
//         <Carousel.Item className="hero--carousel-item">
//           <img src="./img/card.png" className="d-block w-80" alt="Inspection" />
//         </Carousel.Item>
//       </Carousel>
//     </section>
//   );
// };

// export default HeroSection;
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const HeroSection = () => {
  return (
    <section id="heroSection" className="hero--section">
      <Carousel interval={null} controls={true} indicators={false} nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" />} prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon" />}>
        
        {/* Single wide image carousel item */}
        <Carousel.Item className="hero--carousel-item">
          <img src="./img/top.png" className="d-block w-100" alt="Wide Inspection Image" />
        </Carousel.Item>
        {/* <Carousel.Item className="hero--carousel-item">
          <img src="./img/fur.jpg" className="d-block w-100" alt="Wide Inspection Image" />
        </Carousel.Item>
        <Carousel.Item className="hero--carousel-item">
          <img src="./img/ac.jpg" className="d-block w-100" alt="Wide Inspection Image" />
        </Carousel.Item> */}
        
        <Carousel.Item className="hero--carousel-item">
          <img src="./img/burmy.png" className="d-block w-100" alt="Wide Inspection Image" />
        </Carousel.Item>

        <Carousel.Item className="hero--carousel-item">
          <img src="./img/top2.png" className="d-block w-100" alt="Wide Inspection Image" />
        </Carousel.Item>
        {/* Carousel item with two images side by side
        <Carousel.Item className="hero--carousel-item">
          <div className="d-flex justify-content-center">
            <div className="half-width-image">
              <img src="./img/raj2.jpg" className="d-block w-100" alt="Inspection Image 1" />
            </div>
            <div className="half-width-image">
              <img src="./img/fur.jpg" className="d-block w-100" alt="Inspection Image 2" />
            </div>
          </div>
        </Carousel.Item> */}

      </Carousel>
    </section>
  );
};

export default HeroSection;
